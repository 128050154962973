
    import {getPublicPath, register} from '/usr/local/data/service/src/node/build/react/3.x/entrypoints/shared.js'
    const requireEntry = () => require('/cache/build/build_vtex_master/vtex.render-runtime@8.136.1/src/react/error.tsx')

    __webpack_public_path__ = getPublicPath('vtex.render-runtime@8.136.1')

    const {renderHotReload, setupHMR} = register(requireEntry, module, 'vtex.render-runtime@8.136.1', 'vtex.render-runtime@8.x', 'error')
    if (module.hot && setupHMR) {
      require('/usr/local/data/service/node_modules/webpack/hot/dev-server.js')
      const hotLog = require('/usr/local/data/service/node_modules/webpack/hot/log.js')
      const hotEmitter = require('/usr/local/data/service/node_modules/webpack/hot/emitter.js')

      setupHMR(hotEmitter, hotLog)
      if (renderHotReload) {
        module.hot.accept('/cache/build/build_vtex_master/vtex.render-runtime@8.136.1/src/react/error.tsx', renderHotReload)
      }
    }
